export type IProperty = {
  bnr: string;
  fnr: string;
  gnr: string;
  komm: string;
};

export class Property {
  bnr: string;
  fnr: string;
  gnr: string;
  komm: string;

  constructor(property: IProperty) {
    this.komm = property.komm;
    this.gnr = property.gnr;
    this.bnr = property.bnr;
    this.fnr = property.fnr;
  }

  dash(): string {
    return this.komm + '-' + this.gnr + '-' + this.bnr + '-' + this.fnr;
  }

  toString(): string {
    return this.komm + '-' + this.gnr + '/' + this.bnr + '/' + this.fnr;
  }
}
