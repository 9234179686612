export type IPropertyData = {
  data: any;
  farms: any;
};

export class PropertyData {
  data: any;
  farms: any;

  constructor(propertyData: IPropertyData) {
    this.data = propertyData.data;
    this.farms = propertyData.farms;
  }
}
