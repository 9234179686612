import { Grunneiendom, Teig } from '../property';

/*
This class have information of one search or one added grunneiendom/Landbrukseiendom that the user have made.
*/
export class SoektEiendom {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _driftsenterpoint_x: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _driftsenterpoint_y: number;
  private _grunneiendomListe: Grunneiendom[];
  private _hovednummer: string; // knr-gnr-bnr-fnr
  private _isFarm: boolean;
  private _isVisible: boolean;
  private _lastTimeDateDownload: string; // "2018-01-11T12:16:53.059+0000"
  private _lastVerificationTime: string; // "2018-01-11T12:16:53.059+0000"
  private _srid: number;

  get driftsenterpoint_x(): number {
    return this._driftsenterpoint_x;
  }

  set driftsenterpoint_x(value: number) {
    this._driftsenterpoint_x = value;
  }

  get driftsenterpoint_y(): number {
    return this._driftsenterpoint_y;
  }

  set driftsenterpoint_y(value: number) {
    this._driftsenterpoint_y = value;
  }

  get grunneiendomListe(): Grunneiendom[] {
    return this._grunneiendomListe;
  }

  set grunneiendomToListe(value: Grunneiendom[]) {
    this._grunneiendomListe = value;
  }

  get hovednummer(): string {
    return this._hovednummer;
  }

  set hovednummer(value: string) {
    this._hovednummer = value;
  }

  get isFarm(): boolean {
    return this._isFarm;
  }

  set isFarm(value: boolean) {
    this._isFarm = value;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  set isVisible(value: boolean) {
    this._isVisible = value;
  }

  get lastTimeDateDownload(): string {
    return this._lastTimeDateDownload;
  }

  set lastTimeDateDownload(value: string) {
    this._lastTimeDateDownload = value;
  }

  get lastVerificationTime(): string {
    return this._lastVerificationTime;
  }

  set lastVerificationTime(value: string) {
    this._lastVerificationTime = value;
  }

  get soektGid(): string {
    return this._soektGid;
  }

  get srid(): number {
    return this._srid;
  }

  set srid(value: number) {
    this._srid = value;
  }

  constructor(
    private _soektGid: string, // knr-gnr-bnr-fnr-> for search or added property
  ) {
    if (_soektGid[0] === '0') {
      this._soektGid = _soektGid.slice(1);
    }
    this._isVisible = true;
  }

  bnr(): string {
    return this._soektGid.split('-')[2];
  }

  fnr(): string {
    return this._soektGid.split('-')[3];
  }

  getReadableHnr(): string {
    if (this._hovednummer === undefined) {
      return '';
    }
    const parts = this._hovednummer.split('-');
    const komm = (parts[0].length < 4 ? '0' : '') + parts[0];
    return komm + '-' + parts[1] + '/' + parts[2] + '/' + parts[3];
  }

  getReadableHnrWithoutFnr(): string {
    const h = this.getReadableHnr();
    return h.substr(h.length - 2) === '/0' ? h.slice(0, -2) : h;
  }

  getReadableSoektGid(): string {
    const parts = this._soektGid.split('-');
    const komm = (parts[0].length < 4 ? '0' : '') + parts[0];
    return komm + '-' + parts[1] + '/' + parts[2] + '/' + parts[3];
  }

  /**
   * Return a list of teigs  every grunneiendom filterd on the
   * ownership specified
   * @param  ownershipTypes An array of ownership-codes (ie 'M', 'Sx' etc..)
   * @return                An array of the selected teiger
   */
  getTeiger(ownershipTypes: string[] = []) {
    const teiger: Teig[] = [];
    if (ownershipTypes.length === 0) {
      return teiger;
    }
    const isFarm = this.isFarm;
    for (const grunneiendom of this._grunneiendomListe) {
      // console.log("#####Jobber med denne grunneiendommen:", grunneiendom);
      for (const teig of grunneiendom.teigListe) {
        const own = isFarm ? teig.ownership : teig.ownershipproperty;
        // console.log("Teigens ownership er:", own);
        if (ownershipTypes.indexOf(own) !== -1) {
          teiger.push(teig);
        }
      }
    }
    return teiger;
  }

  gnr(): string {
    return this._soektGid.split('-')[1];
  }

  hasDriftsenterPoint(): boolean {
    return !!(this._driftsenterpoint_x && this._driftsenterpoint_y);
  }

  komm(): string {
    let komm = this._hovednummer.split('-')[0];
    if (komm.length < 4) {
      komm = '0' + komm;
    }
    return komm;
  }
}
