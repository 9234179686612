import { inject, Injectable } from '@angular/core';
import { MapConfig } from '@config/map.config';
import { ConfigService } from '@services/config.service';
import { MapTools } from '@tools/map.tools';
import { LegendType } from 'app/legend/legend.type';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import ImageLayer from 'ol/layer/Image';
import Layer from 'ol/layer/Layer';
import ImageWMS from 'ol/source/ImageWMS';
import Vector from 'ol/source/Vector';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LegendService {
  private addedPropertiesLegend: LegendType = {
    header: true,
    id: 'Eiendomsgrenser_added',
    name: 'Eiendom lagt til',
    type: 'Image',
    url: 'assets/legend_added_eiendomsgrense.png',
  };
  private defaultLegends: LegendType[] = [
    {
      header: true,
      id: 'DRIFTSSENTER',
      name: 'Driftssenter',
      vectors: [
        {
          fill: 'red',
          stroke: 'black',
          text: '',
          type: 'Point',
        },
      ],
    },
    {
      header: true,
      id: 'Eiendomsgrenser',
      name: 'Eiendomsgrenser',
      type: 'Image',
      url: 'assets/legend_eiendomsgrense.png',
      vectors: [],
    },
  ];
  private gardskartConfig: any;

  legends: LegendType[] = [];

  private legendArray = new BehaviorSubject<any>(this.legends);

  protected cs = inject(ConfigService);

  legendArray$ = this.legendArray.asObservable();

  constructor() {
    const cs = this.cs;

    this.legends = this.defaultLegends.slice();
    cs.getClasses().subscribe(config => {
      this.gardskartConfig = config;
    });
  }

  /**
   * Setting the legends array back to default
   */
  // private resetLegends() {
  //   // must make a copy of th array otherwise we work on the default itself
  //   this.legends = this.defaultLegends.slice();
  //   this.legendArray.next(this.legends);
  // }

  private getVectorLegends(layer: any | Layer) {
    if (layer.get('id') === 'printBox') {
      return;
    }
    let arrObj: LegendType;
    const layerProps = layer.getProperties();
    let type: string;
    let fill: any;
    let stroke: any;
    if (layer.getSource().getFeatures()[0].getGeometry() instanceof Point) {
      type = 'Point';
      fill = layer.getStyle()[0].getImage().getFill().getColor();
      stroke = layer.getStyle()[0].getImage().getStroke().getColor();
    } else if (layer.getSource().getFeatures()[0].getGeometry() instanceof LineString) {
      type = 'Line';
      fill = layer.getStyle()[0].getImage().getFill().getColor();
      stroke = layer.getStyle()[0].getImage().getStroke().getColor();
    } else if (layer.getSource().getFeatures()[0].getGeometry() instanceof Polygon) {
      type = 'Polygon';
      if (layer.getStyle()[0] === undefined) {
        return;
      }
      fill = layer.getStyle()[0].getImage().getFill().getColor();
      stroke = layer.getStyle()[0].getImage().getStroke().getColor();
    }
    if (layerProps.legend) {
      // Special legend
      arrObj = {
        header: true,
        id: layerProps.id,
        name: layerProps.name,
        vectors: [
          {
            fill: fill,
            stroke: stroke,
            text: '',
            type: type,
          },
        ],
      };
      if (!this.isLegendInList(arrObj.id)) {
        this.legends.unshift(arrObj);
      }
    }
  }

  private getWMSLegends(layer: ImageLayer<ImageWMS>) {
    const baseUrl = layer.getSource().getUrl();
    const p = layer.getSource().getParams();
    let arrObj: LegendType;
    let layerHeader = false;
    const layerProps = layer.getProperties();
    if (layerProps['legend']) {
      // Special legend
      arrObj = {
        header: true,
        id: layerProps['id'],
        name: layerProps['name'],
        type: 'Image',
        url: layerProps['legend'],
      };
      if (!this.isLegendInList(arrObj.id)) {
        this.legends.unshift(arrObj);
      }
    } else {
      // Standard legend
      let splitLayers = [];
      if (p.LAYERS instanceof Array) {
        splitLayers = p.LAYERS; // More than one layer in wms
      } else {
        splitLayers[0] = p.LAYERS; // One layer in wms
      }

      for (let i = 0; i < splitLayers.length; i++) {
        const inputUrl = this.getLayerStandardLegendUrl(baseUrl, splitLayers[i]);
        if (i === splitLayers.length - 1) {
          // Only header for last wms layer
          layerHeader = true;
        }

        arrObj = {
          header: layerHeader,
          id: layer.getProperties()['id'] + '_' + i,
          name: layer.getProperties()['name'],
          type: 'Image',
          url: inputUrl,
        };
        if (!this.isLegendInList(arrObj.id)) {
          this.legends.unshift(arrObj);
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private WMSLegendRemove(inId: string) {
    const newLegends = [];
    for (const item of this.legends) {
      const index = item.id.indexOf(inId);
      if (index !== 0) {
        newLegends.push(item);
      }
    }
    this.legends = newLegends;
  }

  /**
   * Add legend for added properties to this.legends
   */
  addAddedPropertyLegend() {
    // put the added property legend into legend array after the ordinary Eiendomsgrese-entry
    for (let i = 0; i < this.legends.length; i++) {
      if (this.legends[i].id === 'Eiendomsgrenser') {
        this.legends.splice(i + 1, 1, this.addedPropertiesLegend);
        this.legendArray.next(this.legends);
        break;
      }
    }
  }

  /**
   * Produces the legend-config for the gardskart-type specified and adding the
   * config to the ledgens array
   */
  addGardskartLegend(type: string, bg: string) {
    const config = this.gardskartConfig;
    if (config === undefined) {
      return;
    }
    if (!type.length) {
      return;
    }
    // Remove the gardskartLayer if present, using the filter-function
    this.legends = this.legends.filter(item => {
      return !MapTools.isGardskartId(item.id);
    });
    // Add gardskartlayer to the beginning of legends array
    const itemConfig = config.filter((e: { id: string }) => e.id === type)[0];
    if (itemConfig?.hasLegend) {
      this.legends.unshift(this.gardskartLegendConfig(itemConfig, type, bg));
    }
    this.legendArray.next(this.legends);
  }

  /**
   * Will create a config object for describing legend
   *
   * @param  {any} config
   * @param  {any} gkType
   * @param  {any} bgType
   * @return {any} Eks: {
   *    *                name: "Markslag (AR5) 13 klasser",
   *                     layer_id: "GARDSKART_ar5kl13'"
   *                     classes: [
   *                                 {id: "t1",
   *                                  name: "Fulldyrka jord",
   *                                  color: "#ffd16e",
   *                                  rgba: "rgba(255, 209, 110, 0.75)"},
   *                                 {id: "t2",
   *                                  name: "Overflatedyrka jord",
   *                                  color: "ffff4c",
   *                                  rgba: "rgba(255, 255, 76, 0.75)"}
   *                               ]
   *                     }
   */
  gardskartLegendConfig(config: any, gkType: string, bgType: string): any {
    const isFlybilder: boolean = bgType === MapConfig.ORTHO;
    const isPotentiallySymbol: boolean = gkType.startsWith('ar5kl') && isFlybilder;
    const newLegendConfig: LegendType = {
      header: true,
      id: config.layerId,
      name: config.name,
      vectors: [],
    };
    for (const item of config.configurations) {
      if (item.hasLegend) {
        const isSymbol: boolean = isPotentiallySymbol && item.img;
        newLegendConfig.vectors.push({
          fill: isPotentiallySymbol ? 'rgba(0,0,0,0)' : item.rgba,
          isOlSymbol: isSymbol,
          stroke: isFlybilder ? 'yellow' : 'black',
          text: item.name,
          type: 'Polygon',
          url: 'img/arstat/' + item.img + '.gif',
        });
      }
    }
    return newLegendConfig;
  }

  getLayerStandardLegendUrl(baseUrl: string, layer: string) {
    let standardParams = '?VERSION=1.1.1&service=WMS&' + 'request=GetLegendGraphic&format=image/png&layer=';
    if (baseUrl.includes('?')) {
      standardParams = '&VERSION=1.1.1&service=WMS&' + 'request=GetLegendGraphic&format=image/png&layer=';
    }
    standardParams += layer;
    return baseUrl + standardParams;
  }

  isLegendInList(id: string) {
    return this.legends.some(l => l.id === id);
  }

  /**
   * Remove legend for added properties from this.legends
   *
   */
  removeAddedPropertyLegend() {
    for (let i = 0; i < this.legends.length; i++) {
      if (this.legends[i].id === 'Eiendomsgrenser_added') {
        // remove Eiendomsgrenser_added from legend array
        this.legends.splice(i, 1);
        this.legendArray.next(this.legends);
        break;
      }
    }
  }

  updateLegends(layer: ImageLayer<ImageWMS>, off: boolean) {
    if (layer.getProperties()['type'] !== 'base') {
      if (layer.getSource() instanceof ImageWMS) {
        if (layer.getProperties()['legend'] !== 'no') {
          if (off) {
            this.WMSLegendRemove(layer.getProperties()['id']);
          } else {
            this.getWMSLegends(layer);
          }
        }
      } else if (layer.getSource() instanceof Vector) {
        if (layer.getProperties()['legend'] !== 'no') {
          if (off) {
            this.WMSLegendRemove(layer.getProperties()['id']);
          } else {
            this.getVectorLegends(layer);
          }
        }
      }
      // Send new legendArray to component
      this.legendArray.next(this.legends);
    }
  }
}
