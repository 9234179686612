export function getResolution(scale) {
  const UNITS_RATIO = 39.37; // inches/meter
  const DPI = 96; // px/inch
  // console.log('window.devicePixelRatio --> ', window.devicePixelRatio);

  // Scale = resolution * inches per map unit (m) * dpi
  // Map scale = 1 : (ScreenRes pixels/inch * 39.37 inches/meter * 156543.04 meters/pixel * cos(latitude) / (2 ^ zoomlevel))
  // return Math.round(scale / (UNITS_RATIO * DPI));
  return scale / (UNITS_RATIO * DPI);

  // return scale / Math.sqrt(2) * 0.000373993195986947;
}
