import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, publishReplay, refCount, share } from 'rxjs/operators';

import { ApiConfig } from '../_config';

@Injectable()
export class ConfigService {
  private configRequests = {};

  private http = inject(HttpClient);

  /*
    Used to make sure only one request for configFile is sent.
    Will be used to return ongoing request in case of condurrent requests.
   */
  private obsDriftssenter: Observable<any>;

  /**
   * Parse error and return as Observable (throw it - must be caught)
   * @param  {Response}       error     error response from the Observable to be parsed
   * @return {Observable}               returns an Observable containing the error message
   */
  private handleError(error: any | Response) {
    let errMsg: string;

    if (error instanceof Response) {
      const body = error || '';
      const err = body['error'] || JSON.stringify(body);

      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return observableThrowError(errMsg);
  }

  /**
   * will retreive the gardskart_classes config.
   * @return {Observable<any>} Either an ongoing request or shared data
   */
  getClasses(): Observable<any> {
    const url = `${ApiConfig.proxiedBackendUrl}configuration/gkclassesgroups`;
    return this.getConfig(url).pipe(
      map(res => {
        return res;
      }),
    );
  }

  /**
   * Will perform an http.get with the nostore and must-revaidate headers set in request to avoid
   * caching in browser. The reqest is stored in service, and returned on previous requests
   * in order to make sure only one request to resource is sent.
   * @param  {string} url to file
   * @return {Observable}
   */
  getConfig(url: string): Observable<any> {
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Cache-Control': 'no-cache, no-store, must-revalidate',

      Expires: 'Sat, 26 Jul 1997 05:00:00 GMT',

      Pragma: 'no-cache',
    }); // HTTP 1
    const options = { headers: headers };
    if (Object.prototype.hasOwnProperty.call(this.configRequests, url)) {
      return this.configRequests[url];
    }
    this.configRequests[url] = this.http
      .get(url, options)
      .pipe(publishReplay(1), refCount(), catchError(this.handleError));

    return this.configRequests[url];
  }

  /**
   * Retrive the dritsenter-configuration
   * @return Observable for subscription to shared  json response
   */
  getDriftssenterConfig(): Observable<any> {
    const jsonFile = 'config/driftssenter.json';
    return (this.obsDriftssenter = this.getConfig(jsonFile).pipe(
      map(res => {
        return res;
      }),
      share(),
    ));
  }
}
