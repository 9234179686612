import { Injectable } from '@angular/core';

@Injectable()
export class DrawService {
  private drawLabelArr = [];
  private drawNR = 0;

  get lastDrawNr(): number {
    return this.drawNR;
  }

  addDrawNr(drawLabel: boolean): number {
    this.drawNR = this.drawNR + 1;
    if (drawLabel) {
      this.drawLabelArr.push(this.drawNR);
    }
    return this.drawNR;
  }

  hasDrawLabel(drawNr: number): boolean {
    return this.drawLabelArr.indexOf(drawNr) > -1;
  }

  resetDrawings() {
    this.drawNR = 0;
    this.drawLabelArr = [];
  }

  updateDrawNumber(existing: number) {
    this.drawNR = existing;
  }
}
