import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class ErrorService {
  private errorToken = 'errorMessage';

  @Output() newError = new EventEmitter<boolean>(true);

  /**
   * Delete an error message from session storage
   *
   * @param {string} errorToken Token to remove from session storage
   */
  private deleteErrorMessage(errorToken: string): void {
    sessionStorage.removeItem(errorToken);
  }

  /**
   * Retrieve an error message from session storage
   *
   * @param  {string} errorToken Token to retrieve data from in session storage
   * @return {string}            Error message found in the session storage
   *                             with the specified token
   */
  private retrieveErrorMessage(errorToken: string): string {
    const errorMessage: string = sessionStorage.getItem(errorToken);

    if (!errorMessage) {
      return '';
    }

    return errorMessage;
  }

  /**
   * Save an error message in the session storage
   *
   * @param {string} errorToken   Token to save it as in session storage
   * @param {string} errorMessage Error message to be saved for retrieval later
   */
  private saveErrorMessage(errorToken: string, errorMessage: string): void {
    sessionStorage.setItem(errorToken, errorMessage);
  }

  /**
   * Fetch an error (if any) from session storage
   * @return {string}   The error message found (empty if none are found)
   */
  getError(): string {
    const errorMessage = this.retrieveErrorMessage(this.errorToken);

    this.deleteErrorMessage(this.errorToken);

    return errorMessage;
  }

  /**
   * Check if there exist errors in the session storage
   * @return {boolean} True if there is errors present
   */
  hasError(): boolean {
    return this.retrieveErrorMessage(this.errorToken) !== '';
  }

  /**
   * Store an error message in the session storage
   *
   * @param {string} errorMessage Error message to be stored
   */
  putError(errorMessage: string): void {
    // console.log('putError', errorMessage);
    this.saveErrorMessage(this.errorToken, errorMessage);
    this.newError.emit(true);
  }
}
