import { inject, Injectable } from '@angular/core';
import { Grunneiendom, Property, PropertyData, SoektEiendom, Teig, UpdatedPropertiesInterface } from '@models/property';
import { select, Store } from '@ngrx/store';
import { ADD_PROPERTY } from '@reducers/added-property.reducer';
import { StoreStates } from '@reducers/store-states';
import { MiscTools } from '@tools/misc.tools';
import GeoJSON from 'ol/format/GeoJSON';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { ApiConfig } from '../_config';

// the default ownership types (for teiger) to be displayed
const DEFAULT_TEIGER = ApiConfig.defaultOwners;
const SPECIAL_TEIGER = ApiConfig.specialOwners;

@Injectable()
export class PropertyService {
  private addedPropertiesSubscription: Subscription;
  private addedProperty = new BehaviorSubject<boolean>(false);
  private allOwners = [];
  private driftsSenterPresent = new Subject<boolean>();
  private mainProperty = new Subject<SoektEiendom>();
  private soektEiendomsListe: SoektEiendom[] = [];
  private store = inject<Store<StoreStates>>(Store);

  addedEiendomsListe: SoektEiendom[] = [];
  addedProperties: Observable<UpdatedPropertiesInterface>;
  driftsSenterPresent$ = this.driftsSenterPresent.asObservable();
  mainProperty$ = this.mainProperty.asObservable();

  get hasAddedPropeties$(): Observable<boolean> {
    return this.addedProperty.asObservable();
  }

  constructor() {
    const store = this.store;

    this.allOwners = DEFAULT_TEIGER.concat(SPECIAL_TEIGER);
    this.addedProperties = store.pipe(select('addedProperties'));
    this.addedPropertiesSubscription = this.addedProperties.subscribe(addedProperties => {
      const happened = addedProperties.type;
      if (happened === 'ADD_PROPERTY') {
        this.addedProperty.next(true);
      }
      if (happened === 'REMOVE_PROPERTY') {
        this.removeProperty(addedProperties.property);
        if (this.getAddedEiendomsListe().length < 1) {
          this.addedProperty.next(false);
        }
      }
      if (happened === 'RESET_ADDED_PROPERTIES') {
        this.emptyAddedEiendomsListe();
      }
    });
  }

  private addNewSoektEiendom(soektEiendomParams) {
    this.addedEiendomsListe.push(soektEiendomParams);
  }

  private addSoektEiendom(soektEiendomParams) {
    this.soektEiendomsListe.push(soektEiendomParams);
  }

  private findAddedProperty(gnr: string): number {
    return this.addedEiendomsListe.findIndex(item => item.hovednummer === gnr);
  }

  private findTeigListe(teigList: Teig[], gid: string, isFarm: boolean): Teig[] {
    const allTeigLists: any = {};
    const tmpTeigList: Teig[] = [];
    const tmpDefaultTeigList: Teig[] = [];
    const tmpSpecialOwnerTeigList: Teig[] = [];
    for (let i = 0, len = teigList.length; i < len; i++) {
      const teig = teigList[i];
      const own = isFarm ? teig.ownership : teig.ownershipproperty;
      if (teig.gid === gid) {
        tmpTeigList.push(teig);
        if (own !== 'E') {
          if (DEFAULT_TEIGER.indexOf(own) > -1) {
            tmpDefaultTeigList.push(teig);
          } else {
            tmpSpecialOwnerTeigList.push(teig);
          }
        }
      }
    }
    allTeigLists.tmpTeigList = tmpTeigList;
    allTeigLists.tmpDefaultTeigList = tmpDefaultTeigList;
    allTeigLists.tmpSpecialOwnerTeigList = tmpSpecialOwnerTeigList;
    return allTeigLists;
  }

  private formateDate(date) {
    date = date ? date : '2018-01-11T12:16:53.059';

    // IE does not want '+0000' at the end of the string, substitute with 'Z'
    const pos = date.indexOf('+');
    if (pos > 0) {
      date = date.substring(0, pos);
    }

    if (date.indexOf('Z') < 0) {
      date = date + 'Z';
    }
    const d = new Date(date);
    return this.date2String(d);
  }

  private getEiendomFromList(gid: string, list: SoektEiendom[]): SoektEiendom {
    for (let i = 0, len = list.length; i < len; i++) {
      const item = list[i];
      if (item.getReadableSoektGid() === gid || item.getReadableHnr() === gid) {
        return item;
      }
    }
    return null;
  }

  private isGrunneiendomInListe(gnr: string, grunneiendomList: Grunneiendom[]) {
    return grunneiendomList.some(eiendom => eiendom.gid === gnr);
  }

  /**
   * It converts a number to string and add a leading 0 if shorter than 2 digits
   * @return  string with length 2
   * @param nn
   */
  private pad(nn: number): string {
    const xx = nn.toString();
    return (nn < 10 ? '0' : '') + xx;
  }

  private removeProperty(property) {
    const s = property.dash();
    const noLeadingZero = s[0] === '0' ? s.slice(1) : s;
    const index = this.findAddedProperty(noLeadingZero);
    if (index < 0) {
      // console.log('property to be removed has not been found');
    } else {
      this.addedEiendomsListe.splice(index, 1);
    }
    return;
  }

  private triggerNewProperty() {
    if (this.getSoektEiendom()) {
      this.mainProperty.next(this.getSoektEiendom());
    } else {
      this.mainProperty.next(new SoektEiendom('*-*-*-*'));
    }
  }

  private updateProperties(propertyData: any) {
    const subProperties = [];
    const propertyList = propertyData.getReadableHnr().split(/-|\//);
    const property = new Property({
      bnr: propertyList[2],
      fnr: propertyList[3],
      gnr: propertyList[1],
      komm: propertyList[0],
    });

    for (const subProperty of propertyData.grunneiendomListe) {
      const subPropertyList = subProperty.gid.split('-');
      subProperties.push(
        new Property({
          bnr: subPropertyList[2],
          fnr: subPropertyList[3],
          gnr: subPropertyList[1],
          komm: MiscTools.padWithZero(subPropertyList[0], 4),
        }),
      );
    }

    this.store.dispatch({
      property: property,
      subProperties: subProperties.length > 0 ? subProperties : undefined,
      type: ADD_PROPERTY,
    });
  }
  /*
   This is the service for the  farm-model
  */
  addPropertyToModel(propertyData: PropertyData, additionalProperty = false) {
    const format = new GeoJSON();
    const propertyFeatures = format.readFeatures(propertyData.data.jsonMapDoc);
    const mainProperty = propertyFeatures[0];
    const isFarm = mainProperty.get('is_farm') || false;
    const komm = mainProperty.get('municipality_nr');
    const mpGnr = mainProperty.get(isFarm ? 'farm_gnr' : 'gnr');
    const mpBnr = mainProperty.get(isFarm ? 'farm_bnr' : 'bnr');
    const mpFnr = mainProperty.get(isFarm ? 'farm_fnr' : 'fnr');
    const hnr = komm + '-' + mpGnr + '-' + mpBnr + '-' + mpFnr;
    // check that this property is not already in the list
    if (this.isEiendomInSoektEiendomListe(hnr) || this.isEiendomInAddedEiendomListe(hnr)) {
      return false;
    } else {
      propertyData.farms.srid = propertyData.data.srid;
      propertyData.farms.hovednummer = hnr;
      propertyData.farms.isFarm = isFarm;
      const ltdd = propertyData.data.lastTimeDateDownload;
      const lvt = propertyData.data.lastVerificationTime;
      propertyData.farms.lastTimeDateDownload = this.formateDate(ltdd);
      propertyData.farms.lastVerificationTime = this.formateDate(lvt);
      propertyData.farms.driftsenterpoint_x = propertyFeatures[0].get('driftsenterpoint_x');
      propertyData.farms.driftsenterpoint_y = propertyFeatures[0].get('driftsenterpoint_y');
      // add all grunneiendommer and teiger to array
      const teigList: Teig[] = [];
      const grunneiendomList: Grunneiendom[] = [];
      // loop through feature and fill up farm classes
      for (let i = 0, len = propertyFeatures.length; i < len; i++) {
        const gnr =
          propertyFeatures[i].get('municipality_nr') +
          '-' +
          propertyFeatures[i].get('gnr') +
          '-' +
          propertyFeatures[i].get('bnr') +
          '-' +
          propertyFeatures[i].get('fnr');
        // Make new teig object
        const teig = new Teig(propertyFeatures[i].get('parcel_id'), gnr);
        teig.ownership = propertyFeatures[i].get('ownership');
        teig.ownershipproperty = propertyFeatures[i].get('ownershipproperty');
        teig.other_connected_properties = propertyFeatures[i].get('other_connected_properties');
        teig.teigNum = propertyFeatures[i].get('teig_nr');
        teig.valid = propertyFeatures[i].get('is_valid');
        const own = isFarm ? teig.ownership : teig.ownershipproperty;
        if (this.allOwners.indexOf(own) > -1) {
          teigList.push(teig);
          // Make new grunneiendom object
          const grunneiendom = new Grunneiendom();
          grunneiendom.gid = gnr;
          // Is it first time, add grunneiendom to grunneiendomList
          if (i === 0) {
            grunneiendomList.push(grunneiendom);
          } else {
            if (!this.isGrunneiendomInListe(gnr, grunneiendomList)) {
              grunneiendomList.push(grunneiendom);
            }
          }
        } else {
          console.log('Unrecognized ownership');
        }
      }
      //  find all teiger for each grunneiendom
      for (let i = 0, len = grunneiendomList.length; i < len; i++) {
        let allTeigLists: any = {};
        allTeigLists = this.findTeigListe(teigList, grunneiendomList[i].gid, isFarm);
        grunneiendomList[i].teigListe = allTeigLists.tmpTeigList;
        grunneiendomList[i].defaultTeigListe = allTeigLists.tmpDefaultTeigList;
        grunneiendomList[i].specialOwnerTeigListe = allTeigLists.tmpSpecialOwnerTeigList;
      }
      propertyData.farms.grunneiendomToListe = grunneiendomList;
      // Add soekt eiendom with grunneiendommer and teiger

      if (additionalProperty) {
        this.addNewSoektEiendom(propertyData.farms);
        this.updateProperties(propertyData.farms);
      } else {
        this.addSoektEiendom(propertyData.farms);
      }

      if (this.soektEiendomsListe.length === 1) {
        // first property, trigger the  mainProperty subject
        this.triggerNewProperty();
      }

      return true;
    }
  }

  clearModel() {
    this.soektEiendomsListe = new Array<SoektEiendom>(0);
  }

  /**
   * [date2String formate a date]
   * @param d            Date: Mon Mar 12 2018 13:25:58 GMT+0100
   * @param time         true or false for time, if false: 12.03.2018
   * @return {String}     [formatted lastTimeDateDownload to 12.03.2018 13:25]
   */
  date2String(d: Date, time = true): string {
    // console.log(d.toLocaleString('nb'));
    let s = '';

    if (!time) {
      s = this.pad(d.getDate()) + '.' + this.pad(d.getMonth() + 1) + '.' + this.pad(d.getFullYear());
    } else {
      s =
        this.pad(d.getDate()) +
        '.' +
        this.pad(d.getMonth() + 1) +
        '.' +
        this.pad(d.getFullYear()) +
        ' ' +
        this.pad(d.getHours()) +
        ':' +
        this.pad(d.getMinutes());
    }
    // console.log(s);
    return s;
  }

  emptyAddedEiendomsListe(): void {
    this.addedEiendomsListe = [];
    return;
  }

  getAddedEiendomsListe() {
    return this.addedEiendomsListe;
  }

  getEiendom(gid: string): SoektEiendom {
    if (this.soektEiendomsListe) {
      const p = this.getEiendomFromList(gid, this.soektEiendomsListe);
      if (p) {
        return p;
      }
    }
    if (this.addedEiendomsListe) {
      const p = this.getEiendomFromList(gid, this.addedEiendomsListe);
      if (p) {
        return p;
      }
    }
    return null;
  }

  getGrunneiendom(gid: string): Grunneiendom {
    const L = this.soektEiendomsListe.concat(this.addedEiendomsListe);
    for (let i = 0, length = L.length; i < length; i++) {
      const gList = L[i].grunneiendomListe;
      const hnr = L[i].getReadableSoektGid();
      const gll = gList.length;
      for (let gli = 0; gli < gll; ++gli) {
        const g = gList[gli];
        if (g.getReadableGid() === gid) {
          g.hovednummer = hnr;
          return g;
        }
      }
    }
    return null;
  }

  /**
   * [getPropertyInfoToAlert] make a string to use for Alert (orange banner)
   * @param  propertyData object with info from request and our model
   * @return  {string}    [eks. 0111-10/1/0 (12.02.2018)]
   */
  getPropertyInfoToAlert(propertyData): string {
    let propertyInfo = '';
    if (propertyData && propertyData.data && propertyData.farms) {
      const hnr = propertyData.farms.getReadableHnr();
      let date = propertyData.data.lastVerificationTime;
      const pos = date.indexOf('+');
      if (pos > 0) {
        date = date.substring(0, pos);
      }
      if (date.indexOf('Z') < 0) {
        date = date + 'Z';
      }
      const d = new Date(date);
      const lastTimeDateDownloadFormatted = this.date2String(d, false);
      propertyInfo = hnr + ' (verifisert ' + lastTimeDateDownloadFormatted + ') ';
    }
    return propertyInfo;
  }

  getSoektEiendom() {
    if (this.soektEiendomsListe && this.soektEiendomsListe.length > 0) {
      return this.soektEiendomsListe[0];
    }
    return null;
  }

  hasDriftsenter(): boolean {
    const farm = this.getSoektEiendom();
    return farm ? farm.hasDriftsenterPoint() : false;
  }

  isEiendomInAddedEiendomListe(gnr: string) {
    return this.isEiendomInListe(gnr, this.addedEiendomsListe);
  }

  isEiendomInListe(gnr: string, list: SoektEiendom[]) {
    return list.some(item => item.soektGid === gnr || item.hovednummer === gnr);
  }
  isEiendomInSoektEiendomListe(gnr: string) {
    return this.isEiendomInListe(gnr, this.soektEiendomsListe);
  }

  triggerDriftsenter() {
    if (this.hasDriftsenter()) {
      this.driftsSenterPresent.next(true);
    } else {
      this.driftsSenterPresent.next(false);
    }
  }

  /**
   * [updateLastVerificationTime set new lastVerificationTime on the model]
   * @param lastVerificationTime
   */
  updateLastVerificationTime(lastVerificationTime) {
    this.getSoektEiendom().lastVerificationTime = this.formateDate(lastVerificationTime);
  }
}
