export class Teig {
  private _bnr: number;
  private _fnr: number;
  private _gkType: string;
  private _gnr: number;
  private _komm: number;
  private _nrTeigs: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _other_connected_properties: string;
  private _ownership: string; // sx,s,f/ff/fx,t
  private _ownershipproperty: string;
  private _sum: number;
  private _teigNum: number;
  private _valid: boolean;
  private _visible: boolean;

  get bnr(): number {
    return this._bnr;
  }

  set bnr(value: number) {
    this._bnr = value;
  }

  get fnr(): number {
    return this._fnr;
  }

  set fnr(value: number) {
    this._fnr = value;
  }

  get gid(): string {
    return this._gid;
  }

  get gk_type(): string {
    return this._gkType;
  }

  set gk_type(value: string) {
    this._gkType = value;
  }

  get gnr(): number {
    return this._gnr;
  }

  set gnr(value: number) {
    this._gnr = value;
  }

  get municipality_nr(): number {
    return this._komm;
  }

  set municipality_nr(value: number) {
    this._komm = value;
  }

  get nr_teigs(): number {
    return this._nrTeigs;
  }

  set nr_teigs(value: number) {
    this._nrTeigs = value;
  }

  get other_connected_properties(): string {
    return this._other_connected_properties;
  }

  set other_connected_properties(value: string) {
    this._other_connected_properties = value;
  }

  get ownership(): string {
    return this._ownership;
  }

  set ownership(value: string) {
    this._ownership = value;
  }

  get ownershipproperty(): string {
    return this._ownershipproperty;
  }

  set ownershipproperty(value: string) {
    this._ownershipproperty = value;
  }

  get sum(): number {
    return this._sum;
  }

  set sum(value: number) {
    this._sum = value;
  }

  get teig_id(): number {
    return this._teig_id;
  }

  set teig_id(value: number) {
    this._teig_id = value;
  }

  get teigNum(): number {
    return this._teigNum;
  }

  set teigNum(value: number) {
    this._teigNum = value;
  }

  get valid(): boolean {
    return this._valid;
  }

  set valid(value: boolean) {
    this._valid = value;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }

  constructor(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _teig_id: number,
    private _gid: string, // knr-gnr-bnr-fnr
  ) {}
}
